<template>
  <div class="layout">
    <v-layout>
      <CartDrawer v-if="showCartDrawer" />
      
      <AppNav v-if="showAppNav" />
      <!-- <AppTopNav v-if="showAppNav" /> -->
      <v-main :class="{ 'style-main': applyClass }">
        <slot></slot>
      </v-main>
    </v-layout>
  </div>
</template>

<script setup>
import AppNav from "./AppNav.vue";
import AppFooter from "./AppFooter.vue";
import CartDrawer from "./CartDrawer.vue";
import { ref, watch, computed } from "vue";
import { useRouter } from "vue-router";

const drawer = ref(false);
const ignorePages = ref(['login']);
const router = useRouter();
const showCartDrawer = ref(false);
const showAppNav = ref(false);
const applyClass = ref(false);



watch(
  () => router.currentRoute.value.name,
  (to) => {
    const shouldShowCartDrawer = !ignorePages.value.includes(to);
    const shouldShowAppNav = !ignorePages.value.includes(to);
    const shouldApplyClass = !ignorePages.value.includes(to);
    showCartDrawer.value = shouldShowCartDrawer;
    showAppNav.value = shouldShowAppNav;
    applyClass.value = shouldApplyClass;
  }
);
</script>
<style>
.style-main{
  margin-top: 100px;
}
</style>