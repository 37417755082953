import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from 'pinia'

// Emitter Config
import mitt from "mitt"
const Emitter  = mitt();
// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "./assets/custom.css";
const vuetify = createVuetify({
  components,
  directives
})
createApp(App).use(vuetify).provide("emitter",Emitter).use(createPinia()).use(router).mount('#app')
