<template>
  <div class="drawer">
    <v-navigation-drawer
      v-model="drawer"
      location="left"
      width="70"
      color="#2e7675"
      align="center"
    >
      <img
        src="../../assets/images/logo3.svg"
        border="0"
        alt=""
        width="70"
        class="drawer-content"
      />
      <div v-for="(item, index) in drawerIcons" :key="index" @click="goToIconPage(item.link)">
        <img :src="item.iconsPath" border="0" alt="" width="50" />
        <v-tooltip activator="parent" location="right">{{
          item.hint
        }}</v-tooltip>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script setup>
import { inject, ref } from "vue";
import { useRouter } from "vue-router";
const emitter = inject("emitter");
const openCart = () => {
  emitter.emit("openCart");
};
const drawer = ref(true);

emitter.on("openCart", () => {
  drawer.value = !drawer.value;
});

const drawerIcons = ref([
  {
    iconsPath: require("@/assets/SVGS/book-icon.svg"),
    hint: "hint",
    link:"/",
  },
  {
    iconsPath: require("@/assets/SVGS/message-icon.svg"),
    hint: "hint",
    link:"/",
  },
  {
    iconsPath: require("@/assets/SVGS/chat-icon.svg"),
    hint: "hint",
    link:"/",
  },
  {
    iconsPath: require("@/assets/SVGS/megaphone-icon.svg"),
    hint: "hint",
    link:"/",
  },
  {
    iconsPath: require("@/assets/SVGS/setting-icon.svg"),
    hint: "setting",
    link:"/setting",
  },
]);
const router = useRouter();
const goToIconPage = (link) => {
  router.push(link);
};
</script>
<style>
.drawer-content {
  padding-top: 10px;
  /* padding-bottom: 120px; */
}
</style>