<template>
  <div class="app-nav">
    <v-app-bar color="#1c7070" height="fit-content">
      <v-row>
        <v-col>
          <AppTopNav />
          <!-- <AppNavSecond /> -->
          <v-container fluid>
            <v-row>
              <v-col>
                <ul
                  class="text-white d-flex justify-space-between"
                  style="list-style: none"
                >
                  <div v-for="(item, index) in links" :key="index" class="pl-2">
                    <li>
                      <router-link :to="item.link" exact class="custom-link">{{
                        item.title
                      }}</router-link>
                    </li>
                  </div>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-app-bar>
    <!-- <AppTopNav /> -->
  </div>
</template>
<script setup>
import AppTopNav from "./AppTopNav.vue";
// import AppNavSecond from "./AppNavSecond.vue";
import { inject, ref } from "vue";

const emitter = inject("emitter");
const openCart = () => {
  emitter.emit("openCart");
};

const links = ref([
  {
    title: "home",
    link: "/",
  },
  {
    title: "Verified",
    link: "/",
  },
  {
    title: "Tender",
    link: "/tender",
  },
  {
    title: "Current tender",
    link: "/",
  },
  {
    title: "Transport order",
    link: "/assgin-order",
  },
  {
    title: "Freight",
    link: "/offer-page",
  },
  {
    title: "Vechicle space",
    link: "/vehicle-space",
  },
  {
    title: "Warehouse",
    link: "/warehouse",
  },
  {
    title: "Add user",
    link: "/",
  },
  {
    title: "Tracking",
    link: "/",
  },
  {
    title: "Routes & costs",
    link: "/",
  },
  {
    title: "Transport barometer",
    link: "/",
  },
  {
    title: "International services",
    link: "/",
  },
]);
</script>
<style>
.custom-link {
  color: #fff;
  text-decoration: none;
}
.custom-link:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}
.custom-link.router-link-active {
  text-decoration: underline;
  text-underline-offset: 5px;
}
</style>