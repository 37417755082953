<template>
  <div class="top-nav">
    <v-row>
      <v-col cols="12">
        <v-sheet color="white">
          <div class="d-flex pt-2 pb-2">
            <v-row>
              <v-icon size="25" @click="openCart">mdi-menu</v-icon>
              <h3 class="ml-2">IgtGo</h3>
              <h3 class="ml-5">ID:00000</h3>
            </v-row>
            <v-row align="end" justify="center">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn
                    color="black"
                    variant="outlined"
                    v-bind="props"
                    density="comfortable"
                    class="mr-5 btn-style"
                    >Create New
                  </v-btn>
                </template>
                <v-list color="green">
                  <v-list-item
                    v-for="(item, index) in createitems"
                    :key="index"
                    :value="index"
                    @click="gotToOtherPage(item.link)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn
                    color="black"
                    variant="outlined"
                    v-bind="props"
                    density="comfortable"
                    class="btn-style"
                  >
                    Search
                  </v-btn>
                </template>
                <v-list color="green">
                  <v-list-item
                    v-for="(item, index) in searchitems"
                    :key="index"
                    :value="index"
                    @click="gotToOtherPage(item.link)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
    
  <script setup>
import { inject, ref } from "vue";
import { useRouter } from "vue-router";
const emitter = inject("emitter");
const openCart = () => {
  emitter.emit("openCart");
};
const createitems = ref([
  {
    title: "Fright",
    link: "offer-page",
  },
  {
    title: "Vehicle Space",
    link: "vehicle-space",
  },
  {
    title: "Warehouse Offer",
    link: "warehouse",
  },
  {
    title: "Tender",
    link: "tender",
  },
  {
    title: "Assign order",
    link: "new-assgin-order",
  },
  {
    title: "Recevied order",
    link: "new-recevied-order",
  },
]);
const searchitems = ref([
  {
    title: "Fright Search",
    link: "search-offer",
  },
  {
    title: "Find Warehouse Space",
    link: "warehouse-search",
  },
]);
const router = useRouter();
const gotToOtherPage = (link) => {
  router.push(link);
};
</script>
    <style>
.top-nav .btn-style {
  width: 30%;
}
</style>