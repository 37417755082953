import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/offer-page",
    name: "offerPage",
    component: () => import("../views/OfferView.vue"),
  },
  {
    path: "/search-Offer",
    name: "searchOffer",
    component: () => import("../views/SearchOfferView.vue"),
  },
  {
    path: "/vehicle-space",
    name: "vehicleSpace",
    component: () => import("../views/VehicleSpace.vue"),
  },
  {
    path: "/warehouse",
    name: "warehouse",
    component: () => import("../views/WarehouseView.vue"),
  },
  ,
  {
    path: "/warehouse-search",
    name: "warehouse-sarch",
    component: () => import("../views/WarehousingSearchView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/SettingView.vue"),
  },
  {
    path: "/tender",
    name: "tender",
    component: () => import("../views/TenderView.vue"),
  },
  {
    path: "/new-tender",
    name: "new-tender",
    component: () => import("../views/NewTenderView.vue"),
  },
  {
    path: "/assgin-order",
    name: "assgin-order",
    component: () => import("../views/AssignTransportOrderView.vue"),
  },
  {
    path: "/new-assgin-order",
    name: "new-assgin-order",
    component: () => import("../views/NewAssignOrderView.vue"),
  },
  {
    path: "/recevied-order",
    name: "recevied-order",
    component: () => import("../views/ReceviedTransportOrderView.vue"),
  },
  {
    path: "/new-recevied-order",
    name: "new-recevied-order",
    component: () => import("../views/NewReceviedOrderView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
