<template>
  <div class="home mt-16">
   
  </div>
</template>
<style>

.home .v-text-field input {
  height: 27.2px !important;
}
.home .v-input--density-default .v-field__input {
  min-height: 27.2px;
}
</style>
